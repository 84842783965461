<template>
  <!-- eslint-disable  -->
  <div>
    <b-card ref="filterContent" no-body class="sticky">
      <b-card-body>
        <b-row>
          <b-col lg="6" class="col-xxl d-flex flex-column flex-lg-row justify-content-start">
            <div class="w-100 mb-1 mb-lg-0">
              <b-form-group label="Proyecto" label-for="project" class="">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="proyectos"
                  label="code"
                  input-id="project"
                  :reduce="(proyectos) => proyectos.id"
                  placeholder="Todos"
                  v-model="project_id"
                  @input="getDatos(project_id)"
                  class="select-obra"                
                >
                  <template v-slot:selected-option="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="6" class="col-xxl">
            <b-form-group label="Estado" label-for="estados" class="mr-2 w-100">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="estados"                
                label="text"
                input-id="estados"
                :reduce="(estados) => estados.value"
                placeholder="Todos"
                v-model="selectEstados"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" class="col-xxl">
            <b-form-group label="Tipo" label-for="tipos" class="mr-2 w-100">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tipos"                
                label="text"
                input-id="tipos"
                :reduce="(tipos) => tipos.value"
                placeholder="Todos"
                v-model="selectTipos"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" class="col-xxl">
            <b-form-group label="Prioridad" label-for="tipos" class="mr-2 w-100">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="priorities"                
                label="text"
                input-id="priorities"
                :reduce="(priorities) => priorities.value"
                placeholder="Todos"
                v-model="selectPriority"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" class="col-xxl">
            <b-form-group label="Area" label-for="areas" class="mr-2 w-100">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="areas"                
                label="description"
                input-id="areas"
                :reduce="(areas) => areas.id"
                placeholder="Todos"
                v-model="selectArea"
                @input="getOwners()"
              >
                <!-- <template v-if="project_id=='Todos'" v-slot:selected-option="option">
                  {{ option.description }} - {{ option.project.description }}
                </template> -->
                <template v-if="project_id=='Todos'" slot="option" slot-scope="option">
                  {{ option.description }} - {{ option.project.description }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="col-xxl">
            <b-form-group label="Categorias" label-for="categorias" class="mr-2 w-100">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"                
                label="description"
                input-id="categories"
                :reduce="(categories) => categories.id"
                placeholder="Todos"
                v-model="selectCategory"               
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" class="col-xxl" v-show="userData.role_user[0].role_restriction != 'user-collection'">
            <b-form-group label="Dueños" label-for="responsable" class="mr-2 w-100">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="responsables"
                label="name"
                input-id="responsable"
                :reduce="(responsables) => responsables.id"
                placeholder="Todos"
                v-model="responsable"
              />
            </b-form-group>
          </b-col>
         
          <div
            class="w-100 d-flex align-items-center h-100 justify-content-center mb-1 mb-lg-0 flex-wrap flex-md-nowrap gap-1"
          >
            <b-button class="mr-2 mb-1 mb-md-0" variant="primary" @click="getData">
              <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Filtrar </span>
            </b-button>
            <b-button class="mr-2 mb-1 mb-md-0" variant="primary" @click="exportExcel()">
              <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Exportar </span>
            </b-button>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col lg="6">
        <b-card no-body>
          <b-card-body style="">
            <div class="text-center d-flex flex-column flex-lg-row w-100">
              <div class="w-100 d-flex align-items-center justify-content-center">
                <h3 class="fw-bold mr-1">
                  {{ getPeriodo }}
                </h3>
              </div>
              <div class="w-100 d-flex align-items-center justify-content-center">
                <h3 class="fw-bold mr-1">
                  <strong>Fechas:</strong>
                </h3>
                <h5 class="mb-0">{{ getDates }}</h5>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card no-body>
          <b-card-body style="">
            <div class="text-center d-flex flex-column flex-lg-row w-100">
              <div class="w-100 d-flex align-items-center justify-content-center">
                <h3 class="fw-bold mr-1">
                  <strong>Total de Restricciones:</strong>
                </h3>
                <h5 class="mb-0">{{ Number(totalRestric) ? totalRestric : '-' }}</h5>
              </div>
              <div class="w-100 d-flex align-items-center justify-content-center">
                <h3 class="fw-bold mr-1">
                  <strong>Tiempo de Anticipación:</strong>
                </h3>
                <h5 class="mb-0">{{ Number(leadTime) ? leadTime : '-' }}</h5>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card no-body ref="tableCard">
      <div class="table-overflow">
        <b-table
          class="position-relative"
          empty-text="No existen"
          :fields="visibleFields"
          :hover="true"         
          :items="restricciones"
          no-border-collapse
          ref="selectableTable"        
          show-empty
          :responsive="false"
        >
          <!-- Column: Actions -->
          <template #cell(project.description)="data">
            <span>{{ data.item.project.code }} - {{ data.item.project.description }}</span>
          </template>
          <template #cell(date_identified)="data">
            <span>{{ validDate(data.item.date_identified) }}</span>
          </template>
          <template #cell(date_required)="data">
            <span>{{ validDate(data.item.date_required) }}</span>
          </template>
          <template #cell(date_commited)="data">
            <span>{{ validDate(data.item.date_commited) }}</span>
          </template>
          <template #cell(date_released)="data">
            <span>{{ validDate(data.item.date_released) }}</span>
          </template>
          <template #cell(critic_route)="data">
            <div class="date-liberado-class">
              <span v-if="data.item.critic_route">Sí</span>
              <span v-else>No</span>
            </div>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalRestric }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRestric"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import OwnerService from '@/services/OwnerService'
import CoOwnerService from '@/services/CoOwnerService'
import PeriodService from '@/services/PeriodService'
import ProjectsService from '@/services/ProjectsService'
import RestrictionsService from '@/services/RestrictionsService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AreasService from '@/services/AreasService'
import CategoryService from '@/services/CategoryService'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
const APIURL = process.env.APIURLFILE
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      showLoading: false,
      show: true,
      fields: [
        { key: 'project.description', label: 'Proyecto', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'code', label: 'Cod Registro', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'description', label: 'Descripción', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'period.name', label: 'Semana Identificada', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'owner.name', label: 'Dueño', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'owner.area.description', label: 'Area', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'date_identified', label: 'F. Identificada', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'date_required', label: 'F. Requerida', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'date_commited', label: 'F. Comprometida', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'date_released', label: 'F. Liberada', sortable: false, visible: true, thStyle: { width: '250px' } },
        { key: 'time_anticipation', label: 'Tiempo Anticipado', sortable: false, visible: true, thStyle: { width: '250px' } },      
        { key: 'critic_route', label: '¿Es Ruta Crítica?', sortable: true, visible: true, thStyle: { width: '250px' } },      
        { key: 'id_p6', label: 'ID P6', sortable: true, visible: true, thStyle: { width: '250px' } }      
      ],
      estados: [
        {
          text: 'Todos',
          value: 'Todos'
        },
        {
          text: 'Abierto',
          value: 'Abierto'
        },
        {
          text: 'Cerrado',
          value: 'Cerrado'
        }       
      ],
      tipos: [
        {
          text: 'Todos',
          value: 'Todos'
        },
        {
          text: 'Campo',
          value: 'campo'
        },
        {
          text: 'Critico',
          value: 'critica'
        }       
      ],
      priorities:[
        {
          text: 'Todos',
          value: 'Todos'
        },
        {
          text: 'Alta',
          value: 'Alta'
        },
        {
          text: 'Media',
          value: 'Media'
        } ,
        {
          text: 'Baja',
          value: 'Baja'
        }  
      ],
      totalRestric: null,
      leadTime: null,
      date_start: new Date(),
      date_end: new Date(),
      userData: JSON.parse(localStorage.getItem('userData')),
      
      project_id: JSON.parse(localStorage.getItem('project_id')),
      selectEstados: 'Todos',
      selectTipos:'Todos',
      selectPriority:'Todos',
      responsable: 'Todos',
      selectPeriodo: '',
      records: [],
      proyectos: [],
      periodos: [],
      responsables: [],
      documentSelect: [],
      restricciones: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      isAdd: false,
      navbar: null,
      filterContent: null,
      tableCard: null,
      tableContainer: null,
      selectableTable: null,
      tableHead: null,
      ths: null,
      trs: null,
      apiurl: APIURL,
      areas :[],
      selectArea:"Todos",
      categories:[],
      selectCategory :"Todos"
    }
  },
  components: {
    vSelect,
    flatPickr,
    AppCollapse,
    AppCollapseItem
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    allProjectsSelected() {
      return this.project_id != 'Todos' ? true : false
    },
    getPeriodo() {
      const period = this.periodos.filter((e) => e.id == this.selectPeriodo)
      if (period.length > 0) {
        return period[0].name
      }
      return ''
    },
    getDates() {
      const period = this.periodos.filter((e) => e.id == this.selectPeriodo)
      if (period.length > 0) {
        const fecha =
          moment(period[0].date_start).format('yyyy-MM-DD') +
          ' - ' +
          moment(period[0].date_end).format('yyyy-MM-DD')
        return fecha
      }
      return ''
    }
  },
  mounted() {
    // this.getData()
    this.project_id = this.userData.role_user[0].role_restriction == 'user-collection' || this.userData.role_user[0].role_restriction == 'admin' ? 'Todos' : JSON.parse(localStorage.getItem('project_id')),
    this.getSelect()
    this.getDatos(this.project_id)
    this.getAreas()
    this.getCategories()
    this.getData()
    this.getOwners()
    this.navbar = document.querySelector(".navbar");
    this.filterContent = this.$refs.filterContent;
    this.tableContainer = this.$el.querySelector(".table-overflow");
    this.tableCard = this.$refs.tableCard;
    this.selectableTable = this.$refs.selectableTable.$el;
    this.tableHead = this.selectableTable.querySelector("thead");
    this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');
    this.trs = this.selectableTable.querySelector('tbody').querySelectorAll('tr');

    this.fixedElements()
    this.setupScrollSync();
    new ResizeObserver(this.fixedElements).observe(this.tableCard);
  },
  watch: {
    restricciones(newVal, oldVal) {
      this.$nextTick(() => {
        this.trs = this.selectableTable.querySelector('tbody').querySelectorAll('tr');
        this.fixedElements()
      })
    },
    visibleFields(newVal, oldVal) {
      this.$nextTick(() => {
        this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th');
        this.fixedElements()
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleWindowScroll);
    window.removeEventListener("resize", this.fixedElements);
  },
  methods: {
    async getCategories(){
     
      this.showLoading = true
      let arrayFilter = []
      this.selectCategory = 'Todos'
      if(this.project_id != 'Todos'){
        arrayFilter = [
          {
            keyContains: 'project.id',
            key: 'equals',
            value: this.project_id
          }
        ]
      }
      const url = `?limit=10000&filter=`+JSON.stringify(arrayFilter)
      const respCategories = await CategoryService.getCategorys(url, this.$store)
      console.log('respCategories', respCategories)
      if (respCategories.status) {
        this.categories = respCategories.data.rows
        this.categories.unshift({
          id:0,
          code:"Todos",
          description:"Todos"
        })
       
      }
      this.showLoading = false
    },
    async getAreas(){
      this.showLoading = true
      let arrayFilter = []
      this.selectArea = 'Todos'
      if(this.project_id != 'Todos'){
        arrayFilter = [
          {
            keyContains: 'project.id',
            key: 'equals',
            value: this.project_id
          }
        ]
      }
      if(this.project_id == 'Todos' && this.userData.role_user[0].role_restriction == 'user-collection'){
        console.log("FILTRADO AREA TODOS")
        const projectIds = this.userData.projects.map(item => item.id);
        arrayFilter.push({
          keyContains: 'project.id',
          key: 'in',
          value: projectIds
        })
      }
      const url = `?limit=100&filter=`+JSON.stringify(arrayFilter)
      console.log("URL AREAS", url)
      const respAreas = await AreasService.getAreas(url, this.$store)
      console.log('respAreas', respAreas)
      if (respAreas.status) {
        this.areas = respAreas.data.rows
        if(this.userData.role_user[0].role_restriction == 'user-collection'){
          this.areas = this.areas.filter(item => {
            return item.co_owner.some(idObj => idObj.user_id === this.userData.id);
          });
          console.log("FILTRO FORZADO AREA",this.areas)
        }
        this.areas.unshift({
          id:'Todos',
          code:"Todos",
          description:"Todos",
          project:{
            id:0,
            code:"Todos",
            description:"Todos"
          }
        })
      }
      this.showLoading = false
    },
    fixedElements() {
      if (!this.trs[0].classList.contains('b-table-empty-row')) {
        const thsTotalWidth = [...this.ths].reduce((acc, th) => acc + th.offsetWidth, 0);

        if (thsTotalWidth > this.tableCard.offsetWidth) {
          this.ths.forEach((th, index) => {
            th.style.flex = "0 0 " + th.offsetWidth + "px";
          });
        } else {
          this.ths.forEach((th, index) => {
            th.style.flex = "1 1 " + th.offsetWidth + "px";
          });
        }

        this.trs.forEach((tr, index) => {
          const tds = tr.querySelectorAll('td');

          this.ths.forEach((th, index) => {
            tds[index].style.width = th.offsetWidth + "px";

            if (thsTotalWidth > this.tableCard.offsetWidth) {
              tds[index].style.flex = "0 0 " + th.offsetWidth + "px";
            } else {
              tds[index].style.flex = "1 1 " + th.offsetWidth + "px";
            }
          });
        });
        
      } else {
        this.selectableTable.style.width = this.tableHead.querySelector('tr').offsetWidth + 1 + "px";
      }

      this.tableHead.style.width = this.tableCard.offsetWidth - 1 + "px";
      this.selectableTable.style.paddingTop = this.tableHead.offsetHeight + "px";
    },
    setupScrollSync() {
      this.tableHead.addEventListener("scroll", () => {
        this.tableContainer.scrollLeft = this.tableHead.scrollLeft;
        this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`;
      });

      this.tableContainer.addEventListener("scroll", () => {
        this.tableHead.scrollLeft = this.tableContainer.scrollLeft;
        this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`;
      });

      window.addEventListener("scroll", this.handleWindowScroll);
      window.addEventListener("resize", this.fixedElements);
    },
    handleWindowScroll() {
      this.filterContent.style.top = this.navbar.offsetHeight + "px";

      if (this.tableCard.offsetTop - this.filterContent.offsetHeight - this.navbar.offsetHeight - 28 - window.scrollY <= 0) {
        this.tableHead.classList.add("fixed");
        this.tableHead.style.top = this.navbar.offsetHeight + this.filterContent.offsetHeight + "px";
      } else {
        this.tableHead.classList.remove("fixed");
        this.tableHead.style.top = null;
      }
    },
    validDate(fecha) {
      fecha = new Date(fecha)
      if (fecha != null) {
        const year = new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
      }
      return ''
    },
    showFilters() {
      this.isAdd = true
    },
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    async getOwners() {
     
      let arrayFilters = [];
      if(this.project_id != 'Todos'){
        arrayFilters.push({
              keyContains: "area",
              key: "project_id",
              value: { in: this.project_id },
          })
      }
      if(this.selectArea && this.selectArea!='Todos'){
        arrayFilters.push({
          keyContains: 'area_id', key: 'equals', value: this.selectArea
        })
      }
      
     this.responsable=""
      const url =
        `?limit=100&filter=` +
        JSON.stringify(arrayFilters)
      console.log('url DE OWNERS', url)
      if(this.userData.role_user[0].role_restriction == 'user-collection'){
        console.log("FILTRADO RESPONSABLES user collection")
        const resCoOwner = await CoOwnerService.getCoOwners(url, this.$store)
        if (resCoOwner.status) {
          this.responsables = resCoOwner.data.rows
          this.responsables.push({
            id:0,
            type:'001',
            name:'Todos',
            email:'Todos'
          })
        }
      }else{
        console.log("FILTRADO RESPONSABLES")
        const resOwner = await OwnerService.getOwners(url, this.$store)
        if (resOwner.status) {
          this.responsables = resOwner.data.rows
          this.responsables.push({
            id:0,
            type:'001',
            name:'Todos',
            email:'Todos'
          })
        }
      }
      console.log("RESPONSABLES", this.responsables)
    },
    async getDatos(id) {
      this.selectPeriodo = ''
      this.getAreas()
      this.getOwners()
      this.getCategories()
      let url =
        `?limit=100&filter=` +
        JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      console.log('url', url)
      const resp = await PeriodService.getPeriodos(url, this.$store)
    
      if (resp.status) {
      
        this.periodos = resp.data.rows
        if (resp.data.rows.length == 0) {
          this.$swal({
            icon: 'warning',
            title: 'Sin Periodos!',
            text: 'El proyecto seleccionado no tiene periodos.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        }
      }
     
    },
    async getSelect() {
      this.showLoading = true
      const url2 = `?limit=10000&`
      const respProyectos = await ProjectsService.getProyectos(url2, this.$store)
      console.log('respProyectos', respProyectos)
      
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
        if(this.userData.role_user[0].role_restriction == 'user-collection'){
          const records = respProyectos.data.rows
          this.proyectos = records.filter(item => this.userData.projects.some(idObj => idObj.id == item.id))
          console.log("PROYECTOS FILTRADO", this.proyectos)
        }
        this.proyectos.unshift({
          id:'Todos',
          code:"Todos",
          description:"Todos"
        })
      }

      this.showLoading = false
    },
    async getData() {
      this.showLoading = true
      this.arrayFilters = []
      if (this.project_id != null ) {
        if (this.project_id != null && this.project_id != 'Todos') {
          this.arrayFilters.push({
            keyContains: 'project_id',
            key: 'equals',
            value: this.project_id
          })
        }
        if(this.project_id == 'Todos' && this.userData.role_user[0].role_restriction == 'user-collection'){
          console.log("FILTRADO PROYECTOS TODOS")
          const projectIds = this.userData.projects.map(item => item.id);
          this.arrayFilters.push({
            keyContains: 'project.id',
            key: 'in',
            value: projectIds
          })
        }
        if (this.selectEstados != '' && this.selectEstados != null && this.selectEstados != 'Todos') {
          this.arrayFilters.push({
            keyContains: 'status_similar',
            key: 'equals',
            value: this.selectEstados
          })
        }
        if (this.responsable != '' && this.responsable != null  && this.responsable != 'Todos') {
          this.arrayFilters.push({
            keyContains: 'owner_id',
            key: 'equals',
            value: this.responsable
          })
        }       
        /* if(this.userData.role_user[0].role_restriction == 'user-collection'){
          if (this.selectArea != '' && this.selectArea != null  && this.selectArea != 'Todos') {
            this.arrayFilters.push({
              keyContains: 'co_owner.area_id',
              key: 'equals',
              value: this.selectArea
            })
          }       
        }else{
          if (this.selectArea != '' && this.selectArea != null  && this.selectArea != 'Todos') {
            this.arrayFilters.push({
              keyContains: 'owner.area_id',
              key: 'equals',
              value: this.selectArea
            })
          }      
        } */
        if(this.userData.role_user[0].role_restriction == 'user-collection'){
          if (this.selectArea != '' && this.selectArea != null  && this.selectArea != 'Todos') {
            this.arrayFilters.push({
              keyContains: 'co_owner.area_id',
              key: 'equals',
              value: this.selectArea
            })
          }
          console.log("QUE SELECT AREA ESSS", this.selectArea)
          if(this.selectArea == 'Todos'){
            console.log("FILTRADO AREA TODOS")
            const areasIds = this.areas.slice(1).flatMap(obj => obj.co_owner.map(owner => owner.area_id));
            console.log("AREAS IDS", areasIds)
            this.arrayFilters.push({
              keyContains: 'co_owner.area_id',
              key: 'in',
              value: areasIds
            })
          }     
        }else{
          if (this.selectArea != '' && this.selectArea != null  && this.selectArea != 'Todos') {
            this.arrayFilters.push({
              keyContains: 'owner.area_id',
              key: 'equals',
              value: this.selectArea
            })
          }      
        }
        /* if(this.selectArea == 'Todos' && this.userData.role_user[0].role_restriction == 'user-collection'){
          console.log("FILTRADO AREA TODOS")
          const projectIds = this.userData.projects.map(item => item.id);
          this.arrayFilters.push({
            keyContains: 'project.id',
            key: 'in',
            value: projectIds
          })
        } */
        
        if (this.selectTipos != '' && this.selectTipos != null && this.selectTipos != 'Todos') {
          this.arrayFilters.push({
            keyContains: 'type',
            key: 'equals',
            value: this.selectTipos
          })
        }
        if (this.selectPriority != '' && this.selectPriority != null && this.selectPriority != 'Todos') {
          this.arrayFilters.push({
            keyContains: 'priority',
            key: 'equals',
            value: this.selectPriority
          })
        } 
        if (this.selectCategory != '' && this.selectCategory != null  && this.selectCategory != 'Todos') {
          this.arrayFilters.push({
            keyContains: 'category_id',
            key: 'equals',
            value: this.selectCategory
          })
        }              
        console.log('arrayFilters ASAS', this.arrayFilters)
       //EL LIMITE ES EL PROBLEMA POR ESO SOLO HAY POCOS
        let url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` + JSON.stringify(this.arrayFilters)
        console.log("URL REPORTES", url)
        const resp = await RestrictionsService.getRestriccionesReport(url, this.$store)
        console.log('resp', resp)
        if (resp.status) {
          /* if(this.userData.role_user[0].role_restriction == 'user-collection' && this.project_id == 'Todos'){
            const projectIds = this.userData.projects.map(item => item.id);
            this.restricciones = resp.data.rows
            this.restricciones = this.restricciones.filter(item => projectIds.includes(item.project.id))
            console.log("RESTRICCIONES FILTRADO", this.restricciones)
            this.totalRestric = this.restricciones.length

          }else{
            this.restricciones = resp.data.rows
            this.totalRestric = resp.data.responseFilter.total_rows
          } */

          this.restricciones = resp.data.rows
          this.totalRestric = resp.data.responseFilter.total_rows

          let time = 0
          for (let index = 0; index < resp.data.rows.length; index++) {
            const element = resp.data.rows[index]
            time += Number(element.time_anticipation)
          }
          this.leadTime = Number(time / resp.data.rows.length).toFixed(0)
        }
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Aviso!',
          text: 'Seleccione al menos un proyecto y un periodo para empezar.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }

      this.showLoading = false
    },
    async exportExcel() {
      this.showLoading = true

      var fileLink = document.createElement('a')
      console.log(534)
      fileLink.href = this.apiurl + '/restriction/download/report' + '?filter=' + JSON.stringify(this.arrayFilters)
      console.log("FILELINK", fileLink.href);
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink)
      fileLink.click()

      this.showLoading = false
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
@media screen and (min-width: 1500px) {
  .col-xxl {
    max-width: 25%;
  }
}

.table-container {
  max-width: 1300;
  overflow-x: auto;
}
.sticky {
  position: sticky;
  z-index: 3;
}

.table-overflow {
  overflow-x: auto;
  width: 100%;

  thead {
    display: flex;
    overflow-x: auto;
    position: absolute;
    top: 0;

    &.fixed {
      position: fixed;
      transform: translateX(0px) !important;
      z-index: 2;
    }

    tr {
      display: flex;
      flex: 1 1 auto;

      th {
        flex: 0 0 auto;
        padding: 0.72rem 2rem !important;
      }
    }
  }

  tbody {
    overflow-x: auto;

    tr {
      display: flex;

      &.b-table-empty-row {
        td {
          flex: 0 0 100%;
        }
      }

      td {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
